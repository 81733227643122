<template>
  <div class="orderlistmain">
    <div v-for="(item, index) in orderlist" :key="index" class="orderlistdiv">
      <div class="top">
        <div>
          <div class="state state1" v-if="item.state  == 0">待付款</div>
          <div class="state state2" v-if="item.state  == 3">已完成</div>
          <div class="state state3" v-if="item.state  == 4 || item.state  == 5">待付款</div>
          <div class="data">
            {{item.orderTime}} | {{item.userName}} | 订单号：{{item.orderNo}} | {{matchPayMethod(item.paymentMethod )}}
          </div>
        </div>
        <div class="paymoney">
          <span class="smalltitle">支付金额：</span>
          <span class="money">{{item.paidPrice / 100}}元</span>
        </div>
      </div>
      <div class="content">
        <div class="img">
          <img :src="'/com/file/fileDownLoad?filePath=' + item.productCover" alt="" />
        </div>
        <div class="articleright">
          <div class="smalltitle">
            {{item.productName}}
          </div>
          <div class="releaseTime">
            {{item.orderPrice / 100}}元 × {{item.num}}
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="total <= 10"
        layout="prev, pager, next"
        :total="total"
        @current-change="handleChange"
        :current-page.sync="formline.current"
        :page-size="formline.size"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {getShoppOrderByUserPage} from "@/api/personalCenter.js";
export default {
  props: ['state'],
  data() {
    return {
      total: 0,
      orderlist: [], //订单列表
      formline: { size: 10, current: 1,state: null,},
    };
  },
  watch:{
    state:{
      immediate: true,
      handler(a,b){     //a是value的新值，b是旧值
        this.formline.state = a
        this.getShoppOrderByUserPage()
      },
      deep: true,
    }
  },
  methods: {
     matchPayMethod(payMethod){
      switch(payMethod){
        case 1:
          return "现金支付";
        case 2:
          return "刷卡支付";
        case 3:
          return "转账支付";
        case 4:
          return "微信支付";
        case 5:
          return "支付宝支付";
        case 6:
          return "线上小程序支付";
        case 7:
          return "组合支付";
      }
    },
    // 跳页
    handleChange(page) {
      this.formline.current = page;
      this.getShoppOrderByUserPage()
    },
    //获取列表
    getShoppOrderByUserPage(){
      getShoppOrderByUserPage(this.formline).then(res=>{
        if(res.code == 1){
          let data = res.data || {}
          this.orderlist = data.records || []
          this.total = data.total || 0
          console.log(res,'res');
        }
      })
    }
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #1ab394;
}
/deep/.el-pager li:hover {
    color: #1ab394!important;
}
.orderlistmain {
  width: 950px;
  .orderlistdiv {
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    height: 205px;
    border: 1px solid #f1f1f1;
    margin-bottom: 10px;
    .top {
      width: 100%;
      height: 62px;
      border-bottom: 1px solid #f1f1f1;
      display: flex;
      justify-content: space-between;
      .state {
        display: inline-block;
        width: 70px;
        height: 32px;
        line-height: 32px;
        color: #fff;
        text-align: center;
        margin-top: 10px;
        margin-right: 10px;
      }
      .state1{
        background: #f3bb5b;
      }
      .state2{
        background: #1AB394;
      }
      .state3{
        background: #CCCCCC;
      }
      .data {
        display: inline-block;
        height: 32px;
        width: 540px;
        line-height: 32px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
      .paymoney {
        margin-top: 10px;
        .smalltitle {
          display: inline-block;
          height: 32px;
          line-height: 32px;
          font-size: 14px;
          color: #666;
        }
        .money {
          height: 32px;
          line-height: 32px;
          font-size: 18px;
          color: #1ab394;
          display: inline-block;
        }
      }
    }
    .content {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      .img {
        img {
          width: 160px;
          height: 90px;
        }
      }
      .articleright{
        padding: 10px 20px;
        .smalltitle{
            font-size: 18px;
            color: #333333;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .releaseTime{
            color: #999999;
            font-size: 14px;
        }
    }
    }
  }
  .pagination {
    margin-top: 30px;
  }
}
</style>
