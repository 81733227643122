<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="0">
          <Orderlist v-if="activeName == '0'" :state="null"></Orderlist>
      </el-tab-pane>
      <el-tab-pane label="待付款" name="1">
        <Orderlist v-if="activeName == '1'" :state="0"></Orderlist>
      </el-tab-pane>
      <el-tab-pane label="待收货" name="2">
          <Orderlist v-if="activeName == '2'" :state="6"></Orderlist>
      </el-tab-pane>
      <el-tab-pane label="已完成" name="3">
          <Orderlist v-if="activeName == '3'" :state="3"></Orderlist>
      </el-tab-pane>
      <el-tab-pane label="已取消" name="4">
          <Orderlist v-if="activeName == '4'" :state="5"></Orderlist>
      </el-tab-pane>
      <el-tab-pane label="已退款" name="5">
          <Orderlist v-if="activeName == '5'" :state="4"></Orderlist>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Orderlist from "./components/orderlist";
export default {
  components: {
    Orderlist,
  },
  data () {
    return {
        activeName: "0",
    };
  },
  mounted () {},
  methods: {
      handleClick (tab, event) {
        console.log(tab, event);
      }
  },
};
</script>

<style lang="less" scoped>
/deep/.el-tabs__item.is-active {
    color: #1AB394!important;
}
/deep/.el-tabs__item:hover{
    color: #1AB394!important;
}
/deep/.el-tabs__active-bar{
    background-color:  #1AB394!important;
}
</style>
